import React from 'react'
import SEO from '../components/seo';
import Layout from '../components/layout';
import PressKitGrid from '../components/presskit-page/press-kit-grid'
import { graphql, StaticQuery } from 'gatsby';

const PressKit = () => {

    return (
        

        <StaticQuery
        query={graphql`
        query PressKitPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
          <Layout location="">
            <div className="press-kit-wrapper">
              <SEO description='Blix: PressKit' />
              <PressKitGrid imagesPrefix={data.site.siteMetadata.imagesPrefix}/>
            </div>
        </Layout>
        )}
        />
    );
}

export default PressKit;