import React from 'react'
import {Grid, Container, Typography} from '@material-ui/core';

const PressKitGrid = ({imagesPrefix}) => {
    return (
        <div>
        <Container maxWidth="lg" className="press-kit-grid-container" style={{paddingTop: '130px', paddingBottom: '40px'}}>
            <Typography  align='center' style={{color: '#000', marginBottom: '20px', fontSize: '45px'}}>Screenshots</Typography>
            <Grid container direction="row" justify="center" className="mobile" alignItems="center" style={{marginTop: '30px'}}>
            
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/a.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/b.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/c.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" className="mobile" alignItems="center" style={{marginTop: '30px'}}>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/d.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/e.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/f.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" className="mobile" alignItems="center" style={{marginTop: '30px'}}>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/g.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={4} style={{marginTop: '30px', textAlign: '-webkit-center'}}><img src={`${imagesPrefix}/images/h.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" className="desktop" style={{marginTop: '30px'}} spacing={4}>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/1.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/2.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" className="desktop" style={{marginTop: '30px'}} spacing={4}>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/3.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/4.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" className="desktop" style={{marginTop: '30px'}} spacing={4}>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/5.png`}  alt="blix"/></Grid>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/6.png`}  alt="blix"/></Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" className="desktop" style={{marginTop: '30px'}}>
                <Grid item xs={12} sm={12} md={12} style={{marginTop: '30px'}}><img src={`${imagesPrefix}/images/7.png`}  alt="blix"/></Grid>
            </Grid>    
        </Container>
        </div>
    );
}

export default PressKitGrid;